import requestHandler from './dataProvider';

const async = require('async');

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

});


var baseUrl = 'https://api.mamazen.com/v1'
var awsUploadBucket = 'media-mmz';
var environment = process.env.REACT_APP_API
if (environment === 'dev') {
    baseUrl = 'https://dev-api.mamazen.com/v1'
    awsUploadBucket = 'dev-media-mmz'
} else if (environment === 'prod') {
    baseUrl = 'https://api.mamazen.com/v1'
    awsUploadBucket = 'media-mmz'

}

if (process.env.REACT_APP_LOCAL === 'true' || process.env.LOCAL === true) {
    // console.log(`going local`)
    baseUrl = 'http://localhost:3000/v1'
    awsUploadBucket = 'dev-media-mmz'
} else {
    // console.log(` not going local  ${baseUrl} : ${process.env.REACT_APP_LOCAL}`)
}

let AWS = require('aws-sdk');

const awsConfig = {
    "accessKeyId": "AKIA5LFZPO2QMFRSOWNA",
    "secretAccessKey": "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",
    "region": "us-west-2"
};

AWS.config.update(awsConfig);


let s3 = new AWS.S3();

function uploadFile(key, file) {
    let s3Config = {
        Bucket: awsUploadBucket,
        Key: key,
        Body: file
    };
    return new Promise((resolve, reject) => {
        s3.upload(s3Config, (err, resp) => {

            if (err) {
                // console.log(err);
                reject({success: false, data: err});
                return
            }

            resolve({sucess: true, data: resp});
        }).on('httpUploadProgress', ({loaded, total}) => {
            // console.log(key, 'Progress:', loaded, '/', total, `${Math.round(100 * loaded / total)}%`);
        })
    });
}

function processParameters(resource, params) {


    if (resource !== 'Emotions'
        && resource !== 'goals'
        && resource !== 'providers'
        && resource !== 'programs'
        && resource !== 'courses'
        && resource !== 'coaches'
        && resource !== 'emotions'
        && resource !== 'sessions'
        && resource !== 'mamanotes'
        && resource !== 'badges'
        && resource !== 'backgrounds'
        && resource !== 'inductions'
        && resource !== 'mamastories'
        && resource !== 'userSources'
        && resource !== 'ageRanges') {

        return new Promise((resolve) => {

            resolve(params)

        })

    }
    if (params.data === undefined) {

        return new Promise((resolve) => {

            resolve(params)

        })

    }



    if (params.data.unEarnedBadgePicture) {
        params.data.picture = params.data.unEarnedBadgePicture;
    } else if (params.data.earnedBadgePicture) {
        params.data.picture = params.data.earnedBadgePicture;
    }

    if (params.data.basicAudio !== undefined) {

         params.data.title = params.data.basicAudio.title.split('.')[0]

    }

    let hasTitle = (params.data.picture && params.data.picture.image_title === undefined && params.data.picture.title === undefined )

    if (params.data.title === undefined && hasTitle) {
        return new Promise((resolve) => {

             resolve(params)

        })
    }



    let currentTime = Math.round((new Date()).getTime() / 1000);
    let title = ""
    if (params.data.title) {
        title = params.data.title.replace(/\s/g, '')
        title = title.replace(/'/g, '')
        title = title.replace('%s', '')
    }


    let generalFiletype;
    let generalFileName;

    let imageFiletype;
    let imageFileName;
    let videoFiletype;
    let videoFileName;

    let coreAudioFileType;
    let coreAudioFileName;
    let dayAudioFileType;
    let dayAudioFileName;
    let nightAudioFileType;
    let nightAudioFileName;
debugger
    let dayAudioFemaleFileType;
    let dayAudioFemaleFileName;
    let nightAudioFemaleFileType;
    let nightAudioFemaleFileName;


    if (params.data.audio) {

        if (params.data.audio.day) {
            let names =  params.data.audio.day.title.split('.')
            dayAudioFileType = names.pop();
            title = names.pop()
             title = title.replace(/\s/g, '')
            title = title.replace(/'/g, '')
            title = title.replace('%s', '')
            dayAudioFileName = `audio/${title}_day_${currentTime}.${dayAudioFileType}`;
        }
        if (params.data.audio.night) {
            let names =  params.data.audio.night.title.split('.');
            nightAudioFileType = names.pop();
            title = names.pop()
            title = title.replace(/\s/g, '')
            title = title.replace(/'/g, '')
            title = title.replace('%s', '')
            nightAudioFileName = `audio/${title}_night_${currentTime}.${nightAudioFileType}`;
        }

        if (params.data.audio.dayFemale) {
            let names =  params.data.audio.dayFemale.title.split('.')
            dayAudioFemaleFileType = names.pop();
            title = names.pop()
            title = title.replace(/\s/g, '')
            title = title.replace(/'/g, '')
            title = title.replace('%s', '')
            dayAudioFemaleFileName = `audio/${title}_day_${currentTime}.${dayAudioFemaleFileType}`;
        }
        if (params.data.audio.nightFemale) {
            let names =  params.data.audio.nightFemale.title.split('.');
            nightAudioFemaleFileType = names.pop();
            title = names.pop()
            title = title.replace(/\s/g, '')
            title = title.replace(/'/g, '')
            title = title.replace('%s', '')
            nightAudioFemaleFileName = `audio/${title}_night_${currentTime}.${nightAudioFemaleFileType}`;
        }

    }


    if (params.data.basicAudio) {
        let names = params.data.basicAudio.title.split('.');
        coreAudioFileType = names.pop();
        title = names.pop()
        title = title.replace(/\s/g, '')
        title = title.replace(/'/g, '')
        title = title.replace('%s', '')
        coreAudioFileName = `audio/${title}_background_${currentTime}.${coreAudioFileType}`;
    }

    if (params.data.picture) {

        let title = "temp_Title"


        if (params.data.picture.image_title) {
            title = params.data.picture.image_title.split('.').pop();
        }  else {
            title =  params.data.picture.title.split('.').pop();

        }
        imageFiletype = title
        imageFileName = `images/${title}_${currentTime}.${imageFiletype}`;

    }


    if (params.data.video) {

        videoFiletype = params.data.video.title.split('.').pop();
        videoFileName = `videos/${title}_${currentTime}.${videoFiletype}`;

    }

    if (params.data.file) {

        generalFiletype = params.data.file.title.split('.').pop();
        generalFileName = `files/${title}_${currentTime}.${generalFiletype}`;

    }

    return new Promise((resolve, reject) => {



        async.parallel({

            image: function (callback) {

                if (params.data.picture == null) {
                    callback(null, null);
                    return
                }
                convertFileToBase64(params.data.picture).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:image\/\w+;base64,/, ""), 'base64');

                    uploadFile(`${imageFileName}`, buf).then(resultFileData => {

                        delete params.data.picture;
                        params.data.s3ImageKey = imageFileName;

                        callback(null, params.data)

                    })

                });

            },

            file: function (callback) {

                if (params.data.file == null) {
                    callback(null, null);
                    return
                }
                convertFileToBase64(params.data.file).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:text\/csv;base64,/, ""), 'base64');

                    uploadFile(`${generalFileName}`, buf).then(resultFileData => {

                        delete params.data.file;
                        params.data.s3FileKey = generalFileName;

                        callback(null, params.data)

                    })

                });

            },

            audio_core: function (callback) {


                if (params.data.basicAudio == null) {
                    callback(null, null);
                    return
                }

                convertFileToBase64(params.data.basicAudio).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:audio\/\w+;base64,/, ""), 'base64');

                    uploadFile(`${coreAudioFileName}`, buf).then(resultFileData => {

                        delete params.data.basicAudio;
                        params.data.s3AudioKey = coreAudioFileName;
                        callback(null, params.data)

                    }).catch(err => {
                        callback(err, null)

                    });

                });

            },

            audio_night: function (callback) {


                if (params.data.audio == null || params.data.audio.night == null) {
                    callback(null, null);
                    return
                }

                convertFileToBase64(params.data.audio.night).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:audio\/\w+;base64,/, ""), 'base64');

                    uploadFile(`${nightAudioFileName}`, buf).then(resultFileData => {

                        delete params.data.audio.night;
                        params.data.s3AudioKey_ending_night = nightAudioFileName;
                        callback(null, params.data)

                    }).catch(err => {
                        callback(err, null)

                    });

                });

            },

            audio_day: function (callback) {


                if (params.data.audio == null || params.data.audio.day == null) {
                    callback(null, null);
                    return
                }

                convertFileToBase64(params.data.audio.day).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:audio\/\w+;base64,/, ""), 'base64');

                    uploadFile(`${dayAudioFileName}`, buf).then(resultFileData => {

                        delete params.data.audio.day;
                        params.data.s3AudioKey_ending_day = dayAudioFileName;
                        callback(null, params.data)

                    }).catch(err => {
                        callback(err, null)

                    });

                });

            },

            audio_night_female: function (callback) {


                if (params.data.audio == null || params.data.audio.nightFemale == null) {
                    callback(null, null);
                    return
                }

                convertFileToBase64(params.data.audio.nightFemale).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:audio\/\w+;base64,/, ""), 'base64');

                    uploadFile(`${nightAudioFemaleFileName}`, buf).then(resultFileData => {

                        delete params.data.audio.nightFemale;
                        params.data.s3AudioKey_ending_night_female = nightAudioFemaleFileName;
                        callback(null, params.data)

                    }).catch(err => {
                        callback(err, null)

                    });

                });

            },

            audio_day_female: function (callback) {


                if (params.data.audio == null || params.data.audio.dayFemale == null) {
                    callback(null, null);
                    return
                }

                convertFileToBase64(params.data.audio.dayFemale).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:audio\/\w+;base64,/, ""), 'base64');

                    uploadFile(`${dayAudioFemaleFileName}`, buf).then(resultFileData => {

                        delete params.data.audio.dayFemale;
                        params.data.s3AudioKey_ending_day_female = dayAudioFemaleFileName;
                        callback(null, params.data)

                    }).catch(err => {
                        callback(err, null)

                    });

                });

            },

            video: function (callback) {


                if (params.data.video == null) {
                    callback(null, null);
                    return
                }

                convertFileToBase64(params.data.video).then(fileData => {

                    var buf = new Buffer(fileData.replace(/^data:video\/\w+;base64,/, ""), 'base64');

                    uploadFile(`uploads/${videoFileName}`, buf).then(resultFileData => {

                        delete params.data.video;
                        params.data.s3VideoKey = videoFileName;

                        callback(null, params.data)

                    }).catch(err => {
                        callback(err, null)

                    });


                });

            }



        }, function (err, results) {

            if (err != null) {

                reject(err);
                return
            }

            params.data.imageText = params.data.description;

            params = {
                ...params,
                data: {
                    ...params.data,
                },
            }
            resolve(params)


        })

    })


}

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
export default {

    update: (resource, params) => {

        return new Promise((resolve, reject) => {

            processParameters(resource, params).then(function (updatedParams) {

                requestHandler.update(baseUrl, resource, updatedParams).then(result => {

                    resolve(result)

                });

            })
        })

    },

    create: (resource, params) => {

        return new Promise((resolve, reject) => {

            processParameters(resource, params).then(function (updatedParams) {

                requestHandler.create(baseUrl, resource, updatedParams).then(result => {

                    resolve(result)

                });

            })
        })

    },

    // for other request types and resources, fall back to the default request handler


    getList: (resource, params) => requestHandler.getList(baseUrl, resource, params),
    getOne: (resource, params) => requestHandler.getOne(baseUrl, resource, params),
    getMany: (resource, params) => requestHandler.getMany(baseUrl, resource, params),
    getManyReference: (resource, params) => requestHandler.getManyReference(baseUrl, resource, params),
    updateMany: (resource, params) => requestHandler.updateMany(baseUrl, resource, params),
    delete: (resource, params) => requestHandler.delete(baseUrl, resource, params),
    deleteMany: (resource, params) => requestHandler.deleteMany(baseUrl, resource, params),


}
;

