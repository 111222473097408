import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    ReferenceField,
    SelectInput,
    NumberInput,
    DateInput, Filter, TextInput, AutocompleteInput
} from 'react-admin';


const MyFilter = (props) => (
    <Filter {...props}>
        <TextInput
            label="User ID"
            source="user"
            alwaysOn
        />
    </Filter>
);


export const SessionHistoryList = props => (
    <List {...props} filters={<MyFilter />} exporter={false} sort={{ field: 'timestamp', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <ReferenceField source="user" reference="users">
                <TextField source="emailAddress" render={record => record.emailAddress || record.username} />
            </ReferenceField>
            <TextField source="session.title" />
            <TextField source="timezone" />
            <DateField 
                source="timestamp" 
                showTime 
                options={{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                }}
            />
            <NumberField source="sessionLength_s" label="Session Length (seconds)" />
        </Datagrid>
    </List>
);

const SessionHistoryTitle = ({ record }) => {
    return <span>Session History: {record ? `${record.timestamp}` : ''}</span>;
};

const timezones = [
    { id: 'America/Los_Angeles', name: 'America/Los_Angeles' },
    { id: 'America/Denver', name: 'America/Denver' },
    { id: 'America/Chicago', name: 'America/Chicago' },
    { id: 'America/New_York', name: 'America/New_York' },
    { id: 'Europe/London', name: 'Europe/London' },
    { id: 'Europe/Paris', name: 'Europe/Paris' },
    { id: 'Asia/Tokyo', name: 'Asia/Tokyo' },
    { id: 'Asia/Shanghai', name: 'Asia/Shanghai' },
    { id: 'Australia/Sydney', name: 'Australia/Sydney' },
    { id: 'Pacific/Auckland', name: 'Pacific/Auckland' }
];

export const SessionHistoryEdit = props => (
    <Edit title={<SessionHistoryTitle />} {...props}>
        <SimpleForm>
            <TextInput source="user" label="User ID" disabled />
            <ReferenceField source="session" reference="sessions">
                <TextField source="title" />
            </ReferenceField>
            <DateInput 
                source="timestamp" 
                inputProps={{ type: 'datetime-local' }}
                parse={value => value}
                format={value => {
                    if (!value) return '';
                    const date = new Date(value);
                    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                    return localDate.toISOString().substring(0, 16);
                }}
            />
            <SelectInput 
                source="timezone" 
                choices={timezones}
            />
            <NumberInput source="sessionLength_s" label="Session Length (seconds)" />
        </SimpleForm>
    </Edit>
);

export const SessionHistoryCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="user" label="User ID" />
            <ReferenceInput source="session" reference="sessions">
                <SelectInput optionText="title" />
            </ReferenceInput>
            <DateInput 
                source="timestamp" 
                inputProps={{ type: 'datetime-local' }}
                parse={value => value}
                format={value => {
                    if (!value) return '';
                    const date = new Date(value);
                    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                    return localDate.toISOString().substring(0, 16);
                }}
            />
            <SelectInput 
                source="timezone" 
                choices={timezones}
                defaultValue="America/Los_Angeles"
            />
            <NumberInput source="sessionLength_s" label="Session Length (seconds)" />
        </SimpleForm>
    </Create>
); 