import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    required,
    DeleteButton,
    EditButton,
    ReferenceField,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

const UserField = ({ record }) => (
    <span>
        {record.firstName}, {record.emailAddress || record.username}
    </span>
);

export const JournalList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="description" />
            <DateField source="created" />
            <ReferenceField source="user" reference="users" label="User">
                <UserField />
            </ReferenceField>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const JournalTitle = ({ record }) => {
    return <span>Journal {record ? `"${record.name}"` : ''}</span>;
};

export const JournalEdit = props => (
    <Edit title={<JournalTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput source="user" reference="users" disabled fullWidth>
                <SelectInput optionText={record => `${record.firstName} (${record.id})`} label="User" />
            </ReferenceInput>
            <TextInput source="name" fullWidth />
            <TextInput source="description" multiline fullWidth rows={4} />
        </SimpleForm>
    </Edit>
);

export const JournalCreate = props => (
    <Create title="Create a Journal" {...props}>
        <SimpleForm>
            <TextInput source="user" label="User ID" validate={[required()]}/>
            <TextInput source="name" />
            <TextInput source="description" multiline fullWidth rows={4} />
        </SimpleForm>
    </Create>
); 