import {fetchUtils} from 'react-admin';
import {stringify} from 'query-string';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'})
    }
    // add your own headers here

    options.headers.set('Access-Control-Expose-Headers', 'Content-Range')
    const token = localStorage.getItem('token')
    options.headers.set('Authorization', token)

    return fetchUtils.fetchJson(url, options)
};

export default {

    getList: (apiUrl, resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort ? params.sort : {};
        const filter = params.filter

        let key = `$sort[${field}]`
        let orderValue

        const sortField = {}
        if (order === "ASC") {
            orderValue = 1
        } else {
            orderValue = -1
        }

        const query = {
            $limit: perPage,
            $skip: (page - 1) * perPage
        };

        // Special case for sessionHistories
        if (resource === 'sessionHistories') {
            for (const [key, value] of Object.entries(filter)) {
                if (value !== "") {
                    query[key] = value
                }
            }
        } else {
            // Original logic for all other resources
            for (const [key, value] of Object.entries(filter)) {
                if (key === "search" && value !== "") {
                    query[`$search`] = value
                    query[`$caseSensitive`] = false
                } else if (key === "name" && value !== ""){
                    query[`$search`] = value
                    query[`$caseSensitive`] = false
                } else if (value !== "") {
                    query[`${key}[$in]`] = [value]
                }
            }
        }

        query[`${key}`] = orderValue

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => {


            let response = {
                data: json.data.map(data => {
                    return {
                        ...data,
                        id: data._id,
                        source_user: data.source_user ? data.source_user._id : null,
                        referred_user: data.referred_user ? data.referred_user._id : null,
                        user: data.user ? data.user._id : null,
                        coach: data.coach ? data.coach._id : null,
                        latestChat: data.latestChat ? data.latestChat._id : null,
                    }
                }),
                total: json.total,
            }
            return response
        });
    },

    getOne: (apiUrl, resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => {
            let response = {
                data: {
                    ...json,
                    id: json._id,
                }
            }

            // Special case for sessionHistories
            if (resource === 'sessionHistories') {
                response.data.user = json.user ? json.user : null
            } else {
                // Original logic for other resources
                response.data.source_user = json.source_user ? json.source_user._id : null
                response.data.referred_user = json.referred_user ? json.referred_user._id : null
                response.data.coach = json.coach ? json.coach._id : null
                response.data.user = json.user ? json.user._id : null
                response.data.latestChat = json.latestChat ? json.latestChat._id : null
            }

            // Keep the existing arrays handling
            if (json.emotions) {
                let emotions = [...new Set(json.emotions.map(obj => obj._id))]
                response.data.emotions = emotions
            }
            if (json.goals) {
                let goals = [...new Set(json.goals.map(obj => obj._id))]
                response.data.goals = goals
            }
            if (json.programs) {
                let programs = [...new Set(json.programs.map(obj => obj._id))]
                response.data.programs = programs
            }
            if (json.courses) {
                let courses = [...new Set(json.courses.map(obj => obj._id))]
                response.data.courses = courses
            }

            return response
        }),

    getMany: (apiUrl, resource, params) => {
        const query = {};

        let values = []
        for (var i = 0; i < params.ids.length; i++) {

            let value = params.ids[i]
            if (typeof value === 'string' || value instanceof String) {

            } else {
                 value = value._id
            }


            let keyName = `$or[${i}][_id]`

            if (!values.includes(value)) {

                values.push(value)
                query[keyName] = value

            }

        }

        const url = `${apiUrl}/${resource}?${stringify(query)}`;


        return httpClient(url).then(function ({json}) {

            let data = json.data.map(data => {
                return {
                    ...data,
                    id: data._id
                }
            })

            return {data}

        })
            ;
    },

    getManyReference: (apiUrl, resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            // sort: JSON.stringify([field, order]),
            $limit: perPage,
            $skip: (page - 1) * perPage,
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(function ({headers, json}) {

            let data = json.data.map(data => {
                return {...data, id: data._id}
            })
            data.total = json.total
             return json
        });
    },

    update: (apiUrl, resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...json, id: json._id}
        })),

    updateMany: (apiUrl, resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...json, id: json._id}
        }));
    },

    create: (apiUrl, resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...params.data, id: json._id},
        })),

    delete: (apiUrl, resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({
            data: {...json, id: json._id}
        })),

    deleteMany: (apiUrl, resource, params) => {
        // API doesn't handle filters on DELETE route, so we are calling DELETE n times instead
        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({
            data: responses.map(response => response.json),
        }));

    }

};
